<template>
  <div class="invoice-view">
    <v-card flat v-if="isLoading">
      <v-card-text>
        <v-progress-linear :indeterminate="true"></v-progress-linear>
      </v-card-text>
    </v-card>
    <div v-else>
      <v-card flat>
        <ReportViewBar v-if="Inventory" :title="`Inventory Valuation`" />
        <!-- <div class="text-right">
          Date Till: {{ $route.query.date | moment("LL") }}
        </div> -->
        <v-container fluid>
          <table class="center">
            <!-- <v-divider class="mx-3"></v-divider> -->
            <tbody class="report-container">
              <v-card-text class="pt-0">
                <v-data-table dense sort-by="name" :headers="headers" :single-expand="singleExpand"
                  :expanded.sync="expanded" item-key="name" :itemsPerPage="Inventory.length" hide-default-footer
                  :items="Inventory">
                  <template v-slot:item="{ item, isExpanded, expand }">
                    <tr>
                      <td class="text-left">
                        <v-btn @click="expand(true)" icon v-if="!isExpanded">
                          <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                        <v-btn @click="expand(false)" icon v-if="isExpanded">
                          <v-icon>mdi-chevron-up</v-icon>
                        </v-btn>
                      </td>
                      <td class="text-left">{{ item.name }}</td>
                      <td class="text-left">{{ item.value.length }}</td>
                      <td class="text-center">{{ item.totalBalance }}</td>
                      <td class="text-right">
                        {{ item.totalCost | currency }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:body.append="{ headers }" v-if="Inventory.length > 0">
                    <tr>
                      <td :colspan="headers.length - 2" class="text-right font-weight-bold">
                        Total
                      </td>
                      <td class="text-center font-weight-bold">
                        {{ grandTotal.balance }}
                      </td>
                      <td class="text-right font-weight-bold">
                        {{ grandTotal.total | currency }}
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="pa-0">
                      <v-card text class="pl-15">
                        <!-- <v-btn color="success" @click="runOnAll(item)">Run All</v-btn> -->
                        <v-data-table dense :itemsPerPage="item.value.length" sort-by="name" :headers="productHeader"
                          hide-default-footer :items="item.value">
                          <template v-slot:item="{ item }">
                            <tr>
                              <td class="text-left">{{ item.localId }}</td>
                              <td class="text-left">{{ item.name }}</td>
                              <td class="text-center pointer" @click="updateBalanceProduct(item)">{{ item.balance }}</td>
                              <td class="text-right">{{ item.avgCost | currency }}</td>
                              <td class="text-right">
                                {{ item.totalCost | currency }}
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </tbody>
          </table>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import ReportViewBar from "@/components/ReportViewBar";
import reportService from "@/modules/ReportView/service.js";
import { mapGetters, mapActions } from "vuex";
import productService from "@/modules/Product/service.js";

export default {
  data() {
    return {
      showEmailBtn: false,
      total: 0,
      expanded: [],
      singleExpand: true,
      isLoading: true,
      Inventory: [],
      productHeader: [
        {
          text: "ID",
          align: "left",
          value: "localId",
          sortable: true,
        },
        {
          text: "PRODUCT NAME",
          align: "left",
          value: "name",
          sortable: true,
        },

        {
          text: "STOCK",
          align: "center",
          value: "balance",
          sortable: true,
        },
        {
          text: "AVG COST",
          align: "center",
          value: "avgCost",
          sortable: true,
        },
        {
          text: "TOTAL COST",
          align: "right",
          value: "totalCost",
          sortable: true,
        },
      ],
      headers: [
        { text: "", value: "data-table-expand", width: 60, sortable: false },
        {
          text: "CATEGORY NAME",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "PRODUCT COUNT",
          align: "left",
          value: "productCount",
          sortable: true,
        },
        {
          text: "STOCK",
          align: "center",
          value: "totalBalance",
          sortable: true,
        },
        // {
        //   text: "AVG COST",
        //   align: "center",
        //   value: "avgCost",
        //   sortable: true,
        // },
        {
          text: "TOTAL COST",
          align: "right",
          value: "totalCost",
          sortable: true,
        },
      ],
      totalQty: 0,
    };
  },
  computed: {
    ...mapGetters("global", ["currentBusiness"]),
    grandTotal() {
      let total = 0
      let balance = 0
      this.Inventory.map(row => {
        total += row.totalCost
        balance += row.totalBalance
      })
      return {
        total: total,
        balance: balance
      }
    }
  },
  created() {
    this.loadData();
    if (!this.currentBusiness) this.initUser();
  },
  components: { ReportViewBar },
  methods: {
    ...mapActions("global", ["initUser", "initBusiness"]),
    updateBalanceProduct(product) {
      // console.log('i call this')
      productService.updateBalance(product.id).then(result => {
        // console.log('update balance result', result)
        this.loadData()
      }).catch(err => {
        console.log(err)
      })
    },
    runOnAll(items) {
      // console.log('all ', items)
      items.value.reduce((acc, product) => {
        // console.log('product', product,acc)
        this.updateBalanceProduct(product)

        // console.log('acc', acc)

      })
      // this.updateBalanceProduct()
    },
    groupProductsByCategory() {
      const grouped = this.Inventory.reduce((acc, product) => {
        const categoryName = product.Category.name;

        // If the category doesn't exist in the accumulator, create an object for it
        if (!acc[categoryName]) {
          acc[categoryName] = {
            value: [],
            totalBalance: 0, // Initialize total balance
            totalCost: 0     // Initialize total cost
          };
        }

        // Add the product to the respective category
        acc[categoryName].value.push(product);

        // Ensure balance and cost are valid numbers and sum them
        acc[categoryName].totalBalance += product.balance || 0;
        acc[categoryName].totalCost += product.totalCost || 0; // Ensure product.cost is valid

        return acc;
      }, {});

      // Convert the grouped object into an array of { name, value, totalBalance, totalCost } objects
      return Object.keys(grouped).map(categoryName => ({
        name: categoryName,
        value: grouped[categoryName].value,
        totalBalance: grouped[categoryName].totalBalance,
        totalCost: grouped[categoryName].totalCost
      }));

    },
    loadData() {
      reportService.getInventoryValuation().then((response) => {
        this.$Progress.finish();
        this.Inventory = response.data.rows
        document.title = `Report - Inventory Valuation`;
        this.Inventory.map((row) => {
          // row.serialCount = parseInt(row.purchase) - parseInt(row.invoice);
          row.totalCost = row.balance * row.avgCost;
          // console.log('row', row)
          // row.productCount = row.value.length;
          // if (row.serialCount > 0) {
          //   this.total += parseFloat(row.totalCost);
          //   this.totalQty += parseInt(row.serialCount);

          //   this.Inventory.push(row);
          // }
        });

        this.Inventory = this.groupProductsByCategory()
        this.Inventory.map((row) => {
          row.productCount = row.value.length
        })
        // console.log('response response', this.Inventory)

        this.isLoading = false;
      });
      return true;
    },
  },
};
</script>

<style scoped>
@media print {
  container {
    height: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  .no-print {
    display: none;
  }

  .detailBox {
    overflow: visible;
    height: 100%;
  }

  * {
    background: 0 0 !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
}

.paidImg {
  position: absolute;
  left: 40%;
  top: 10%;
}

.signature {
  margin-top: 100px;
}

.center {
  background-color: white;
  margin: auto;
  width: 100%;
}

/* @media print {
  .detailBox * {
    overflow: visible;
    height: 100%;
    page-break-before: always;
  }
}
.detailBox {
  overflow: auto;
  height: 600px;
} */
</style>
