var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"invoice-view"},[(_vm.isLoading)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1)],1):_c('div',[_c('v-card',{attrs:{"flat":""}},[(_vm.Inventory)?_c('ReportViewBar',{attrs:{"title":"Inventory Valuation"}}):_vm._e(),_c('v-container',{attrs:{"fluid":""}},[_c('table',{staticClass:"center"},[_c('tbody',{staticClass:"report-container"},[_c('v-card-text',{staticClass:"pt-0"},[_c('v-data-table',{attrs:{"dense":"","sort-by":"name","headers":_vm.headers,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"name","itemsPerPage":_vm.Inventory.length,"hide-default-footer":"","items":_vm.Inventory},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('tr',[_c('td',{staticClass:"text-left"},[(!isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e(),(isExpanded)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e()],1),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.value.length))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.totalBalance))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalCost))+" ")])])]}},(_vm.Inventory.length > 0)?{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{staticClass:"text-right font-weight-bold",attrs:{"colspan":headers.length - 2}},[_vm._v(" Total ")]),_c('td',{staticClass:"text-center font-weight-bold"},[_vm._v(" "+_vm._s(_vm.grandTotal.balance)+" ")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.grandTotal.total))+" ")])])]}}:null,{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"pl-15",attrs:{"text":""}},[_c('v-data-table',{attrs:{"dense":"","itemsPerPage":item.value.length,"sort-by":"name","headers":_vm.productHeader,"hide-default-footer":"","items":item.value},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.localId))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center pointer",on:{"click":function($event){return _vm.updateBalanceProduct(item)}}},[_vm._v(_vm._s(item.balance))]),_c('td',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("currency")(item.avgCost)))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(item.totalCost))+" ")])])]}}],null,true)})],1)],1)]}}],null,true)})],1)],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }